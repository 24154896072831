import * as R from "ramda"
import { GenericEditorConfig } from "./GenericEditorConfigProviderInterfaces"
import { resolveEditorConfig, runCommand, showRunCommandResult } from "./GenericEditorProviderShared"

export const modalFormMapper = (providers, descriptorProviderName, context): any => {
    const editorConfig = resolveEditorConfig(providers, descriptorProviderName)
    const { entityDescription } = editorConfig
    const loadState = R.path(["params", "loadState"], context) as string
    return {
        dashBoardUrl: "/id/dashboards-editor.new-entry-form",
        dashBoardParams: {
            disabled: loadState !== "editing",
            entityDescription
        }
    }
}

export const modaFormSubmit = (providers, descriptorProviderName, context, formData) => {
    runCommand(providers, descriptorProviderName, context, "new", formData, (editorConfig: GenericEditorConfig, commandData, result) => {
        const showResult = () => showRunCommandResult(providers, editorConfig, commandData, result)
        if (result.statusCode === 409) {
            providers.confirm(result.body, showResult, showResult)
        } else if (result.statusCode == 200) {
            providers.navigation.changeDashBoard("id/00000000-2222-0000-9999-000000000003", { "dashBoardId": formData.id })
        } else {
            showResult()
        }
    })
}