import { ClientApp } from "@sennen/dashboards-react-client"
import { FileViewer } from "./components/FileViewer"
import { HighChart } from "./components/HighChart"
import { HighChartGauge } from "./components/HighChartGauge"
import { HighChartHeatMap } from "./components/HighChartHeatMap"
import { FormButtonBar } from "./components/FormButtonBar"
import { BlockGauge } from "./components/BlockGauge"
import { ArcGauge } from "./components/ArcGauge"
import { FlowLayout } from "./layouts/FlowLayout"
import { TabbedFunctionEditor } from "./components/genericEditor/TabbedFunctionEditor"
import { EditorDashBoardHost } from "./components/genericEditor/EditorDashBoardHost"
import { MonacoEditor } from "./components/genericEditor/MonacoEditor"
import { ObjectIdProvider } from "./providers/objectid/ObjectIdProvider"
import { QuickTracksFormDsMappingProvider } from "./providers/quickTracks/quickTracksFormMappers/QuickTracksFormDsMappingProvider"
import { QuickTracksFormMappingProvider } from "./providers/quickTracks/quickTracksFormMappers/QuickTracksFormMappingProvider"
import { QuickTracksFormSubmitProvider } from "./providers/quickTracks/QuickTracksFormSubmitProvider"
import {
    QuickTracksFormCreateProvider,
    QuickTracksFormEditProvider,
    QuickTracksFormProvider
} from "./providers/quickTracks/QuickTracksFormProvider"
import {
    QuickTracksFormCreateProvider as QuickTracksFormCreateProviderEncapsulated,
    QuickTracksFormEditProvider as QuickTracksFormEditProviderEncapsulated,
    QuickTracksFormProvider as QuickTracksFormProviderEncapsulated
} from "./providers/quickTracks/QuickTracksFormProviderEncapsulated"
import {
    QuickTracksFormSubmitProvider as QuickTracksFormSubmitProviderEncapsulated
} from "./providers/quickTracks/QuickTracksFormSubmitProviderEncapsulated"
import { ColoursProvider } from "./providers/ColoursProvider"
import { SennenFileServiceProvider } from "./providers/SennenFileServiceProvider"
import { AmplitudeProvider } from "@sennen/amplitude"
import { registerEditors } from "./editors/client"

import { DashBoardDebugOverlay } from "./components/genericEditor/DashBoardDebugOverlay"
import { SennenAuthClientComponent } from "./authentication/sennen/SennenAuthClientComponent"
import { OpenLayersMap, OpenLayersMapProvider } from "@sennen/openlayers-map"
import { Gantt } from "./components/schedule/Gantt"
import { GanttProvider } from "./components/schedule/GanttProvider"
import { SennenSystemProvider } from "./providers/SennenProvider/SennenSystemProvider"
import { SennenProvider } from "./providers/SennenProvider/SennenProvider"
import { registerExtension } from "@sennen/dashboard-extension-sennen-core"

import { DashBoardEditorProvider } from "./editors/providers/DashBoardEditorProvider"

let clientApp = new ClientApp()
registerExtension(clientApp)

clientApp.registerLayout("FlowLayout", FlowLayout)

clientApp.registerStaticComponent("SennenAuthClient", SennenAuthClientComponent)
clientApp.registerStaticComponent("DashBoardDebugOverlay", DashBoardDebugOverlay)

clientApp.registerComponent("HighChart", HighChart)
clientApp.registerComponent("HighChartGauge", HighChartGauge)
clientApp.registerComponent("HighChartHeatMap", HighChartHeatMap)
clientApp.registerComponent("FormButtonBar", FormButtonBar)
clientApp.registerComponent("OpenLayersMap", OpenLayersMap)
clientApp.registerComponent("Gantt", Gantt)
clientApp.registerComponent("BlockGauge", BlockGauge)
clientApp.registerComponent("ArcGauge", ArcGauge)
clientApp.registerComponent("FileViewer", FileViewer)
clientApp.registerComponent("TabbedFunctionEditor", TabbedFunctionEditor)
clientApp.registerComponent("EditorDashBoardHost", EditorDashBoardHost)
clientApp.registerComponent("MonacoEditor", MonacoEditor)

clientApp.registerProvider("QuickTracksFormDsMappingProvider", QuickTracksFormDsMappingProvider)
clientApp.registerProvider("QuickTracksFormMappingProvider", QuickTracksFormMappingProvider)
clientApp.registerProvider("QuickTracksFormSubmitProvider", QuickTracksFormSubmitProvider)
clientApp.registerProvider("OpenLayersMapProvider", OpenLayersMapProvider)
clientApp.registerProvider("ObjectIdProvider", ObjectIdProvider)

clientApp.registerProvider("QuickTracksFormCreateProvider", QuickTracksFormCreateProvider)
clientApp.registerProvider("QuickTracksFormEditProvider", QuickTracksFormEditProvider)
clientApp.registerProvider("QuickTracksFormProvider", QuickTracksFormProvider)
clientApp.registerProvider("ColoursProvider", ColoursProvider)
clientApp.registerProvider("GanttProvider", GanttProvider)

clientApp.registerGlobalProvider(QuickTracksFormCreateProviderEncapsulated)
clientApp.registerGlobalProvider(QuickTracksFormEditProviderEncapsulated)
clientApp.registerGlobalProvider(QuickTracksFormProviderEncapsulated)
clientApp.registerGlobalProvider(QuickTracksFormSubmitProviderEncapsulated)
clientApp.registerGlobalProvider(SennenFileServiceProvider)
clientApp.registerGlobalProvider(AmplitudeProvider)
clientApp.registerGlobalProvider(SennenSystemProvider)
clientApp.registerGlobalProvider(SennenProvider) 
clientApp.registerGlobalProvider(DashBoardEditorProvider)

registerEditors(clientApp)

clientApp.start()